<template>
  <div class="main-page contract-detection">
    <custom-header></custom-header>
    <div class="desc">
      <h4>PS合成检测</h4>
      <p>支持传统PS合成方法检测，适用于不同应用场景</p>
    </div>

     <van-tabs
      v-model="activeTab"
      @change="changeTab"
      class="custom-headers-com"
      swipeable
    >
      <van-tab :name="item.key" v-for="(item, index) in tabList" :key="index">
        <template #title>
          <div class="tab-headers has-sub">{{item.title}}</div>
          <div class="tab-headers-sub">PS检测</div>
        </template>
      </van-tab>
    </van-tabs>
    <div>
      <div class="image-box">
        <div class="img-cover">
          <van-image fit="contain" :src="customResultData.resultUrl || customData.url" lazy-load class="main-img">
            <template v-slot:loading>
              <van-loading class="fs-80 lazy-loading" color="#007BFF"/>
            </template>
          </van-image>
        </div>
        <div class="qr-scanner" v-if="customData.loading">
          <div class="box">
            <div class="line"></div>
          </div>
        </div>
      </div>
      <ul class="tip-list">
        <li class="red-block">
          <p class="name"><span>红色框</span></p>
          <p class="tip-desc">PS篡改区域</p>
        </li>
      </ul>
      <div class="result-panel" :class="showResult  ? '' : 'hidden'">
        <h4 class="test-info">检测结果</h4>
        <div class="result-box-border"></div>
        <div class="result-inner">
          <!-- 加v-if为了正常显示动效 -->
          <div v-if="showResult" class="result-box" :class="customResultData.fake ? 'danger' : ''">
            {{tabObj[activeTab][customResultData.fake ? "fakeFont" : "realFont"]}}
          </div>
        </div>
      </div>
    </div>
    <van-button
      type="info"
      class="choose-btn"
      :disabled="customData.loading"
      @click="chooseExample"
      >选择图片</van-button>
    <custom-footer></custom-footer>

    <choose-example
      v-if="chooseShow"
      :dataList="customExampleList"
      @goBack="hideExample"
      @onDetect="onDetect"
      :type="tabObj[activeTab].exampleKey"
    ></choose-example>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import ChooseExample from "@/components/ChooseExample.vue";
import { DATA } from "@/remote";

const TAB_LIST = [
  {key: '1', demoKey: 4, exampleKey: 'contract', method: 'normalImageCheck', title: '证件类',
  realFont: '图中未发现被篡改的证件', fakeFont: '证件被篡改'},
  // {key: '2', demoKey: 6, exampleKey: 'travelCode', method: 'codeCheck', title: '行程码',
  // realFont: '图中未发现被篡改的行程码', fakeFont: '行程码被篡改'},
  {key: '3', demoKey: 5, exampleKey: 'doc', method: 'normalImageCheck', title: '文档类',
  realFont: '图中未发现被篡改的文档', fakeFont: '文档被篡改'},
  {key: '4', demoKey: 9, exampleKey: 'img', method: 'normalImageCheck', title: '自然图像',
  realFont: '图中未发现PS内容', fakeFont: '图像被篡改'}
]

const TAB_OBJ = TAB_LIST.reduce((obj, item) => {
  obj[item.key] = item;
  return obj;
}, {});

const DATA_OBJ = TAB_LIST.reduce((obj, item) => {
  obj[item.key] = {
    url: '',
    loading: false,
    resultData: {},
    exampleList: [],
  };
  return obj;
}, {});

export default {
  name: "ps-detection",
  components: {
    "custom-header": CustomHeader,
    "custom-footer": CustomFooter,
    "choose-example": ChooseExample,
  },
  data() {
    return {
      tabList: TAB_LIST,
      tabObj: TAB_OBJ,
      data: DATA_OBJ,
      activeTab: TAB_LIST[0].key,
      chooseShow: false,
    };
  },
  computed: {
    customExampleList() {
      return this.data[this.activeTab].exampleList || [];
    },
    customData() {
      return this.data[this.activeTab] || {};
    },
    customResultData() {
      return this.customData.resultData || {};
    },
    showResult() {
      return this.customResultData.success || this.customResultData.success === false;
    }
  },
  mounted() {
    this.getDemo(TAB_LIST[0].key, TAB_LIST[0].demoKey);
  },
  methods: {
    getDemo(activeTab, fileType) {
      DATA.getDemo({fileType}).then(res => {
        let {code, data = []} = res || {};
        if (code == 200) {
          this.data = {
            ...this.data,
            [activeTab]: {
              ...this.data[activeTab],
              url: data[0],
              exampleList: data 
            }
          }
          if (!this.data[activeTab].resultData || !this.data[activeTab].resultData.success) {
            this.detection(activeTab, data[0]);
          }
        }
      })
    },

    // 切换tab
    changeTab(value) {
      this.activeTab = value;
      if (!this.customExampleList || !this.customExampleList.length) {
        this.getDemo(value, this.tabObj[value].demoKey);
        return;
      }

      // 检测失败的切换tab需要再次检测
      if (!this.data[value] || !this.data[value].resultData || !Object.keys(this.data[value].resultData).length) {
        this.detection(value, this.data[value].url);
      }
    },

    // 检测
    detection(activeTab, fileUrl) {
      this.data = {
        ...this.data,
        [activeTab]: {
          ...this.data[activeTab],
          loading: true,
          resultData: {},
        }
      }
      let method = this.tabObj[activeTab].method;
      DATA[method]({fileUrl}).then(res => {
        let {code, data = {}} = res || {};
        this.data = {
          ...this.data,
          [activeTab]: {
            ...this.data[activeTab],
            loading: false,
            resultData: data ? {
              ...this.data[activeTab].resultData,
              ...data,
              fake: activeTab == 2 ? data.detResult == 1 :  Number(data.score) > 50,
              resultUrl: activeTab == 2 ? data.resultUrl : data.filename, // 行程码使用resultUrl，其他使用filename
              success: code == 200,
            } : {}
          }
        }
      })
    },

    // 选择示例
    chooseExample() {
      this.chooseShow = true;
    },

    hideExample() {
      this.chooseShow = false;
    },

    onDetect(fileUrl) {
      this.hideExample();
      this.data = {
        ...this.data,
        [this.activeTab]: {
          ...this.data[this.activeTab],
          url: fileUrl,
        }
      }
      this.detection(this.activeTab, fileUrl);
    },
  },
};
</script>

<style lang="less">
  @import "../../assets/less/contractDetection";
</style>
